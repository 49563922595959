import { Injectable } from '@angular/core';
import { CheckoutSession } from '@index/interfaces';
import { FirestoreService } from '@sentinels/services/core/firebase.service';

@Injectable({ providedIn: 'root' })
export class CheckoutSessionsService extends FirestoreService<unknown> {
  protected basePath = 'checkout_sessions';

  async createFirebaseCheckoutSession(checkoutSession: CheckoutSession) {
    return this.collection.add(checkoutSession).then((ref) => ref.id);
  }
}
